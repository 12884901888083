const MAX_CONTENT_LENGTH = 1048576 * 100; // 100 MB

const validate = (input, type) => {
  if (type === 'file') {
    if (!input) return true;
    return input.size <= MAX_CONTENT_LENGTH;
  }
  if (!type || input.length < 1) return true;
  if (type === 'email') return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,7})+$/.test(input);
  return true;
};

export default { validate };
