const BACKEND_URL = 'https://forms.poselytics.com';

const sendForm = async (
  fileType, file, fileName, wantsResults, wantsNewsletter, email, language) => {
  const formdata = new FormData();
  formdata.append('file', file, fileName);
  const jsonPayload = JSON.stringify({
    wants_results: wantsResults || false,
    wants_newsletter: wantsNewsletter || false,
    email: email || '',
    language: language || 'de',
  });
  console.log(jsonPayload);

  formdata.append('json', jsonPayload);
  console.log(email, wantsResults, wantsNewsletter);
  formdata.append('file_type', fileType);
  return fetch(`${BACKEND_URL}/submit`, {
    method: 'POST',
    body: formdata,
  });
};

export default { sendForm };
