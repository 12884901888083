<template>
<section class="min-h-screen pt-20 px-2 md:px-10 lg:w-2/3 mx-auto">
  <div class="w-screen backdrop-blur-md bg-white opacity-90 top-0 h-screen
              left-0 z-50 fixed" v-if="loading">
      <div class="top-1/3 w-screen flex mx-auto flex-col text-background-primary absolute">
        <Icon icon="gg:spinner" class="animate-spin w-full h-28"/>
        <h3 class="text-center pt-5 px-4">
          {{$t('form.loadingText1')}}<br/>{{$t('form.loadingText2')}}</h3>
      </div>
  </div>
    <ProgressDisplayVue :totalPages="pages.length" :currentPage="currentPageIndex + 1"
                        :pageTitle="$t(pages[this.currentPageIndex].title)"/>
    <p class="text-lg pt-5">{{$t(currentPage.text)}}</p>
    <div class="py-5 grid md:grid-flow-col grid-flow-row-dense mx-auto">
      <div v-for="(elem, index) in currentPage.input" :key="index">
        <SelectionButton :selected="pages[this.currentPageIndex].input[index].selected"
                         :text="$t(pages[this.currentPageIndex].input[index].text)"
                         :iconifyIcon="pages[this.currentPageIndex].input[index].iconifyIcon"
                         @click="handleClickEvents(index)"
                         v-if="pages[this.currentPageIndex].input[index].type === 'SelectionButton'"
                         class="m-3"/>
        <FormInput v-if="pages[this.currentPageIndex].input[index].type === 'FormInput'"
                   @input="(x) => handleInput(x, index)"
                   :label="$t(pages[this.currentPageIndex].input[index].label)"
                   :placeholder="$t(pages[this.currentPageIndex].input[index].placeholder)"
                   :inputType="pages[this.currentPageIndex].input[index].inputType"
                   :icon="pages[this.currentPageIndex].input[index].icon"/>
        <FormVideoPlayer v-if="pages[this.currentPageIndex].input[index].type === 'FormVideoPlayer'"
                             :videoSrc="$t(pages[this.currentPageIndex].input[index].videoSrc)"/>
        <FormVideoUpload v-if="pages[this.currentPageIndex].input[index].type === 'FormVideoUpload'"
                         @change="(x) => handleFileUpload(x, index)"/>
      </div>
    </div>
    <div v-if="continueButtonNeeded" @click="continueButtonClick()"
         :class="continueButtonActive ?
            'cursor-pointer hover:border-background-light text-gray-800 border-gray-800' +
            ' hover:text-background-light border-3 delay-150' :
            'cursor-not-allowed text-gray-400 border-gray-400'"
         class="flex flex-col items-center rounded-lg py-5 px-14 relative shadow mt-10 border w-1/2
                mx-auto hover:border-2 mb-10">
      <span class="font-semibold leading-5 text-center select-none">
        {{$t('form.continueButton')}}
      </span>
    </div>
    <div class="w-full flex justify-between pt-4" v-if="false">
      <button class="border border-background-light rounded-lg px-3 py-1 text-background-light
                   hover:text-white hover:bg-background-light duration-150"
                   v-if="currentPageIndex > 0"
                   @click="previousPage">
        Previous</button>
      <button class="border border-background-light rounded-lg px-3 py-1 text-background-light
                   hover:text-white hover:bg-background-light duration-150"
                   v-if="currentPageIndex < pages.length - 1"
                   @click="nextPage">
        Next</button>
    </div>
</section>
</template>
<script>
import { Icon } from '@iconify/vue';
import ProgressDisplayVue from '../components/form/ProgressDisplay.vue';
import SelectionButton from '../components/form/SelectionButton.vue';
import FormInput from '../components/form/FormInput.vue';
import FormVideoPlayer from '../components/form/FormVideoPlayer.vue';
import FormVideoUpload from '../components/form/FormVideoUpload.vue';

import inputValidation from '../helpers/inputValidation';
import formBackend from '../helpers/formBackend';

export default {
  name: 'Form',
  components: {
    ProgressDisplayVue,
    SelectionButton,
    FormInput,
    FormVideoPlayer,
    FormVideoUpload,
    Icon,
  },
  data() {
    return {
      loading: false,
      currentPageIndex: 0,
      pages: [
        {
          title: 'form.questions[1].title',
          text: 'form.questions[1].text',
          input: [
            {
              type: 'FormVideoPlayer',
              videoSrc: 'form.videoSrc',
              goto: 1,
            },
          ],
        },
        {
          title: 'form.questions[0].title',
          text: 'form.questions[0].text',
          layout: 'row',
          input: [
            {
              type: 'SelectionButton',
              text: 'form.answerYes',
              iconifyIcon: 'healthicons:yes',
              selected: false,
              goto: 2,
            },
            {
              type: 'SelectionButton',
              text: 'form.answerNo',
              iconifyIcon: 'healthicons:no',
              selected: false,
              goto: -1,
            },
          ],
        },
        {
          title: 'form.questions[2].title',
          text: 'form.questions[2].text',
          input: [
            {
              type: 'FormVideoUpload',
              file: null,
              goto: 3,
            },
          ],
        },
        {
          title: 'form.questions[3].title',
          text: 'form.questions[3].text',
          layout: 'row',
          input: [
            {
              type: 'SelectionButton',
              text: 'form.answerConfirm',
              iconifyIcon: 'healthicons:yes',
              selected: false,
              goto: 4,
            },
          ],
        },
        {
          title: 'form.questions[4].title',
          text: 'form.questions[4].text',
          layout: 'row',
          input: [
            {
              type: 'SelectionButton',
              text: 'form.answerYes',
              iconifyIcon: 'healthicons:yes',
              selected: false,
              goto: 5,
            },
            {
              type: 'SelectionButton',
              text: 'form.answerNo',
              iconifyIcon: 'healthicons:no',
              selected: false,
              goto: 7,
            },
          ],
        },
        {
          title: 'form.questions[5].title',
          text: 'form.questions[5].text',
          layout: 'row',
          input: [
            {
              type: 'SelectionButton',
              text: 'form.answerYes',
              iconifyIcon: 'healthicons:yes',
              selected: false,
              goto: 6,
            },
            {
              type: 'SelectionButton',
              text: 'form.answerNo',
              iconifyIcon: 'healthicons:no',
              selected: false,
              goto: 6,
            },
          ],
        },
        {
          title: 'form.questions[6].title',
          text: 'form.questions[6].text',
          goto: 3,
          input: [
            {
              type: 'FormInput',
              label: '',
              placeholder: 'john.doe@example.com',
              inputType: 'email',
              icon: 'ic:baseline-email',
              value: '',
              isValid: false,
            },
          ],
        },
        {
          title: 'form.questions[7].title',
          text: 'form.questions[7].text',
          layout: 'row',
          input: [
            {
              type: 'SelectionButton',
              text: 'form.answerDataProcessingConsent',
              iconifyIcon: 'fluent:send-28-filled',
              selected: false,
              submit: true,
              goto: -1,
            },
          ],
        },
      ],
    };
  },
  computed: {
    currentPage() {
      return this.pages[this.currentPageIndex];
    },
    continueButtonNeeded() {
      // Checks if there is a button on the current page. If not it will render a continue button.
      return !this.currentPage.input.some((x) => x.type === 'SelectionButton');
    },
    continueButtonActive() {
      if (!this.continueButtonNeeded) return false;
      if (this.currentPage.input.some((x) => x.type === 'FormVideoPlayer')) return true;
      return this.currentPage.input.every((x) => x.isValid);
    },
  },
  methods: {
    generateLayoutClasses(layout) {
      switch (layout) {
        case 'row':
          return 'flex-row';
        case 'column':
        default:
          return 'flex-column';
      }
    },
    async handleClickEvents(index) {
      if (!this.pages[this.currentPageIndex].input[index].type === 'SelectionButton') return;
      // eslint-disable-next-line no-restricted-syntax
      for (const element of this.pages[this.currentPageIndex].input) {
        element.selected = false;
      }

      this.pages[this.currentPageIndex].input[index].selected = true;
      await new Promise((resolve) => setTimeout(resolve, 150));

      this.routeToNextPage(this.pages[this.currentPageIndex].input[index]);
    },
    nextPage() {
      this.currentPageIndex += 1;
    },
    previousPage() {
      this.currentPageIndex = Math.max(0, this.currentPageIndex - 1);
    },
    routeToNextPage(obj) {
      if (!obj.goto) {
        this.nextPage();
        return;
      }
      const { goto } = obj;
      if (goto === -1) { // Sink of the form DAG
        // TODO trigger form submission if requested (maybe with a certrain key set)
        if (obj.submit) this.submitForm();
        else this.$router.push('/form-end-screen');
        return;
      }
      this.currentPageIndex = goto;
      console.log(`now at ${this.currentPageIndex}`);
    },
    handleInput(x, index) {
      this.pages[this.currentPageIndex].input[index].value = x.target.value;
      this.pages[this.currentPageIndex].input[index].isValid = inputValidation
        .validate(x.target.value, this.pages[this.currentPageIndex].input[index].inputType)
        && this.pages[this.currentPageIndex].input[index].value.length > 0;
    },
    handleFileUpload(x, index) {
      try {
        [this.pages[this.currentPageIndex].input[index].file] = x.target.files;
        this.pages[this.currentPageIndex].input[index].isValid = inputValidation
          .validate(this.pages[this.currentPageIndex].input[index].file, 'file');
      } catch (TypeError) {
        this.pages[this.currentPageIndex].input[index].isValid = false;
      }
    },
    continueButtonClick() {
      if (!this.continueButtonActive) return;
      this.routeToNextPage(this.pages[this.currentPageIndex].input[0]);
    },
    async submitForm() {
      this.loading = true;
      const VALID_TYPES_FOR_EXPORT = ['SelectionButton', 'FormInput', 'FormVideoUpload'];
      const extracted = this.pages.map((x) => x.input)
        .filter((x) => VALID_TYPES_FOR_EXPORT.includes(x[0].type));

      const video = extracted[1][0].file;

      const resp = await formBackend.sendForm(
        video.name.split('.').at(-1),
        video,
        video.name,
        extracted[3][0].selected || false, // wants_results
        extracted[4][0].selected || false, // wants_newsletter
        extracted[5][0].value || '', // email
        this.$root.$i18n.locale, // language
      );
      console.log(resp);

      this.loading = false;
      this.$router.push('/form-end-screen');
    },
  },
};
</script>
