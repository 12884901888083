<template>
  <div :class="`${styleClasses} ${textColor}`">
    <Icon :icon="iconifyIcon" v-if="iconifyIcon" class="w-full h-16" />
    <p class="font-medium leading-5 text-center select-none" :class="textStyling"
      v-if="text">
      {{text}}
    </p>
    <div
      class="w-10 h-10 bg-background-primary shadow rounded-tr absolute top-0 right-0
             flex items-center justify-center" v-if="selected">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 12L10 17L20 7" stroke="white" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
      </svg>
    </div>
  </div>
</template>
<script>
import { Icon } from '@iconify/vue';

export default {
  name: 'SelectionButton',
  components: { Icon },
  props: {
    selected: Boolean,
    text: String,
    iconifyIcon: String,
  },
  computed: {
    styleClasses() {
      const base = 'flex flex-col items-center bg-white rounded-lg py-10 px-14 relative '
                   + 'cursor-pointer shadow';
      // hover:shadow cursor-pointer
      if (this.selected) return `${base} border-2 border-background-primary`;
      return `${base} border border-gray-500`;
    },
    textColor() {
      if (this.selected) return 'text-background-primary';
      return 'text-gray-500 hover:text-background-primary';
    },
    textStyling() {
      if (this.iconifyIcon) return 'mt-10 text-xl';
      return 'text-3xl';
    },
  },
};
</script>
