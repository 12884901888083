<template>
    <div class="relative">
        <span class="text-base font-medium leading-4 text-xs text-gray-400">
            Step {{currentPage}} of {{totalPages}}
        </span> <br/>
        <div class="w-full h-3 bg-gray-200 rounded-full relative my-5">
            <div class="h-3 bg-background-primary absolute top-0 bottom-0 rounded-l-full w-2">
            </div>
            <div class="h-3 bg-background-primary center ml-2 rounded-r-full"
                 :style="`--startPercentage: ${progress}`"></div>
        </div>
            <span class="text-primary-dark font-normal text-3xl">
                {{pageTitle}}</span>
    </div>
</template>
<script>
export default {
  name: 'ProgressDisplay',
  props: {
    currentPage: Number,
    totalPages: Number,
    pageTitle: String,
  },
  data: () => ({
  }),
  computed: {
    progress() {
      return this.currentPage / this.totalPages;
    },
  },
};
</script>
<style scoped>
.center {
    transform: scaleX(var(--startPercentage));
    transform-origin: left;
}
@keyframes bar-animate {
    0% {
        transform: scaleX(var(--startPercentage));
    }
    100% {
        transform: scaleX(var(--endPercentage));
    }
}
</style>
