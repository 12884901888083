<template>
    <div class="mx-auto md:w-1/2 flex flex-col justify-center">
        <div class="mx-auto">
            <label for="email1"
                class="text-gray-800 text-sm font-bold leading-tight tracking-normal mb-2">
                {{label}}</label>
            <div class="relative">
                <div class="absolute flex items-center pl-4 h-full"
                     :class="validationStateTextColor">
                    <Icon :icon="icon" class="h-5" />
                </div>
                <input id="email1" v-model="content" :class="validationStateBorderColor"
                    class="text-gray-600 focus:outline-none focus:border
                     bg-white font-normal w-64 h-10 flex
                    items-center pl-12 text-md rounded border shadow"
                    :placeholder="placeholder" />
            </div>
            <div class="flex items-center mt-2 gap-3" :class="validationStateTextColor"
                 v-if="!isValidInput && content.length > 0">
                <Icon icon="charm:circle-cross"></Icon>
                <p class="text-xs leading-3 tracking-normal">Invalid input.</p>
            </div>
        </div>
    </div>
</template>
<script>
import { Icon } from '@iconify/vue';
import inputValidation from '../../helpers/inputValidation';

export default {
  name: 'FormInput',
  components: { Icon },
  props: {
    label: String,
    placeholder: String,
    inputType: String,
    icon: String,
    value: String,
  },
  data() {
    return {
      content: this.value,
    };
  },
  computed: {
    isValidInput() {
      if (!this.content) return true;
      return inputValidation.validate(this.content, this.inputType);
    },
    validationStateTextColor() {
      return this.isValidInput ? 'text-gray-400' : 'text-red-500';
    },
    validationStateBorderColor() {
      return this.isValidInput ? 'border-gray-300 focus:border-background-primary' : 'border-red-300 focus:border-red-300';
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', this.content);
      this.$emit('isValid', this.isValidInput);
    },
  },
};
</script>
