<template>
<div class="flex mx-auto w-full">
    <div class="rounded-xl mx-auto" style="width: 281px; height: 500px">
        <!--eslint disable-next-line -->
        <iframe :src="videoSrc" scrolling="no" width="281" height="500" frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Poselytics"
                class="rounded-xl overflow-hidden"></iframe>
    </div>
</div>
</template>
<script>
export default {
  name: 'FormVideoPlayer',
  props: {
    videoSrc: String,
  },
};
</script>
