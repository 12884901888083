<template>
<div class="flex flex-col text-center p-5 rounded-lg borde mx-auto">
    <p v-if="invalidFileSize" class="mb-5 text-md text-gray-500 text-">
    {{ $t('form.upload.tooBig1') }} ({{Math.floor(invalidFileSize)}} MB).
    {{ $t('form.upload.tooBig2') }}</p>
    <input type="file" accept="video/*" @change="handleFileUpload( $event )"
           class="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border
                border-gray-300 cursor-pointer focus:outline-none"/>
    <p class="mt-1 text-sm text-gray-500 text-center">{{ $t('form.upload.annotation') }}</p>
</div>
</template>
<script>
import inputValidation from '../../helpers/inputValidation';

export default {
  name: 'FormVideUpload',
  data() {
    return {
      file: null,
      invalidFileSize: undefined,
    };
  },
  methods: {
    handleFileUpload(event) {
      [this.file] = event.target.files;
      if (!this.validFile) {
        this.invalidFileSize = this.file.size / 1048576; // in MB
        this.file = null;
      } else {
        this.invalidFileSize = undefined;
        this.$emit('change', this.file);
      }
    },
  },
  computed: {
    validFile() {
      return inputValidation.validate(this.file, 'file');
    },
  },
};
</script>
